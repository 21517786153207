import { React, useEffect, useState } from 'react';
import {Card, Container, TextField,Button } from '@material-ui/core';

const Form = props => {
    
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if ( window.location.search.includes('success=true') ) {
      setSuccess(true);
    }
  }, []);

return (
    <Container maxWidth='sm'>
 <h2>CONTACT</h2>
 {success && (
          <p style={{ color: "green" }}>Thanks for your message! </p>
        )}
   <Card>
     <form 
       name="strongeye-contact-form" 
       method="POST" 
       data-netlify="true" 
      >
     <input type="hidden" name="form-name" value="strongeye-contact-form" />
        <TextField id="standard-basic" label="name" name="fullname" />
        <TextField id="standard-basic" label="email" name="email" />
        <TextField multiline id="standard-basic" label="message" name="message" />
        <Button type="submit">Send</Button>
       </form>
      </Card>
   </Container>
);
}

export default Form;